





import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { HomeComponent, HomeComponentContent, HomeComponentType } from '~/features/home';

const componentMapper: Record<HomeComponentType, string> = {
  [HomeComponentType.PRODUCTS_CAROUSEL]: 'HomeProductCarouselFragment',
  [HomeComponentType.RECENTLY_VIEWED_CAROUSEL]: 'HomeNonCachedProductsFragment',
  [HomeComponentType.BEST_SELLERS_CAROUSEL]: 'HomeNonCachedProductsFragment',
  [HomeComponentType.OFFERS]: 'HomeOfferComponentFactory',
  [HomeComponentType.FEATURED_CATEGORIES]: 'HomeCategoryFragment',
  [HomeComponentType.FEATURED_BRANDS]: 'HomeFeaturedBrandsFragment',
  [HomeComponentType.ABOUT_BLOCKS]: 'HomeInformativeBannerFragment',
};

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<HomeComponent<HomeComponentContent>[]>,
      default: () => [],
    },
  },
  setup(props) {
    return {
      homeItems: computed(() =>
        props.items.map(item => ({
          ...item,
          component: componentMapper[item.type],
        }))
      ),
    };
  },
});
