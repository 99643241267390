















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { isBefore } from 'date-fns';
import { useSeoMeta } from '~/features/seo';
import { useFlashSale, useHomePage } from '~/features/home';
import { usePagination } from '~/features/pagination';

export default defineComponent({
  name: 'Home',
  setup() {
    useSeoMeta();
    const { currentPage, pageSize, nextPage } = usePagination({
      totalPages: 10,
      currentPage: 1,
      pageSize: 3,
    });
    const { homeContent, isFetching: isFetchingHomeItems, isFetchingPaginatedItems, totalPages } = useHomePage(
      computed(() => ({
        page: currentPage.value,
        pageSize: pageSize.value,
      }))
    );

    const { sale } = useFlashSale();

    // check if
    // 1. There's a sale
    // 2. That it has started or if there is no start time
    // 3. That it has not expired
    const showHappyHour = computed(
      () =>
        sale.value &&
        (!sale.value.startDate || isBefore(new Date(sale.value.startDate), new Date())) &&
        isBefore(new Date(), new Date(sale.value.endDate))
    );

    return {
      homeContent,
      showHappyHour,
      sale,
      isFetchingHomeItems,
      isFetchingPaginatedItems,
      totalPages,
      currentPage,
      nextPage,
    };
  },
  head: {},
});
