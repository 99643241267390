//
//
//
//

export default {
  props: {
    rootMargin: {
      type: String,
      default: '100px 0px 0px',
    },
  },
  data: () => ({
    observer: null,
  }),
  mounted() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('intersect');
          this.$emit('toggleFetch', true);
        } else {
          this.$emit('toggleFetch', false);
        }
      },
      {
        rootMargin: this.rootMargin,
      }
    );

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
