import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3f460739&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=3f460739&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f460739",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HappyHour: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/HappyHour.vue').default,HomeComponentFactory: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/Home/ComponentFactory.vue').default,LoadingDots: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/Loading/Dots.vue').default,InfiniteScrollObserver: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/InfiniteScrollObserver.vue').default})
