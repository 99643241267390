
















import { defineComponent } from '@nuxtjs/composition-api';

/**
 * A component showing a timer for a flash sale
 */
export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  setup() {},
});
