import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { SearchResultPageInfo } from '~/graphql-types.gen';

interface PaginationOptions {
  pageSize: number;
  currentPage: number;
  totalPages: number;
}

const DEFAULT_PAGE_SIZE = 12;

/**
 * Handles paginatable collections that use the SearchResultPageInfo interface
 */
export function usePagination(opts?: Partial<PaginationOptions>) {
  const { query } = useContext();
  const currentPage = ref(opts?.currentPage || Number(query.value?.page || 1));
  const pageSize = ref(opts?.pageSize || DEFAULT_PAGE_SIZE);
  const totalPages = ref(opts?.totalPages || 1);

  const hasNextPage = computed(() => {
    return currentPage.value < totalPages.value;
  });
  const hasPrevPage = computed(() => {
    return currentPage.value > 1;
  });

  function nextPage() {
    if (!hasNextPage.value) {
      return;
    }

    currentPage.value++;
  }

  function prevPage() {
    if (!hasPrevPage.value) {
      return;
    }

    currentPage.value--;
  }

  function goToPage(p: number) {
    currentPage.value = p;
  }

  function updatePageInfo(pageInfo?: SearchResultPageInfo | null) {
    currentPage.value = pageInfo?.current_page || currentPage.value;
    pageSize.value = pageInfo?.page_size || pageSize.value;
    totalPages.value = pageInfo?.total_pages || totalPages.value;
  }

  return {
    currentPage,
    totalPages,
    pageSize,
    nextPage,
    prevPage,
    goToPage,
    updatePageInfo,
    hasNextPage,
    hasPrevPage,
  };
}
